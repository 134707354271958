enum Actions {
  FETCH_REVIEW_QUESTION = 'fetchReviewQuestions',
}

enum Mutations {
  SET_ALL_REVIEW_QUESTIONS = 'setAllReviewQuestions',
  SET_REVIEW_QUESTION = 'setReviewQuestion',
  SET_REVIEW_QUESTIONS = 'setReviewQuestions',
  SET_REVIEW_QUESTIONS_ACTION_ERROR = 'setReviewQuestionsActionError',
  SET_REVIEW_QUESTIONS_LIST_ERROR = 'setReviewQuestionsListError',
  SET_REVIEW_QUESTIONS_LIST_LOADING = 'setReviewQuestionsListLoading',
}

enum Getters {
  GET_ALL_REVIEW_QUESTIONS = 'getAllReviewQuestions',
  GET_REVIEW_QUESTION = 'getReviewQuestion',
  GET_REVIEW_QUESTIONS = 'getReviewQuestions',
  GET_REVIEW_QUESTIONS_ACTION_ERROR = 'getReviewQuestionsActionError',
  GET_REVIEW_QUESTIONS_LIST_ERROR = 'getReviewQuestionsListError',
}

export { Actions, Mutations, Getters };
