import store from '@/store';
import { Getters, Actions, Mutations } from '@/store/enums/AuthEnums';
import {
  Actions as VerifyActions,
  Getters as VerifyGetters,
} from '@/store/enums/VerifyAccountEnums';
import CookieService from '@/core/services/CookieService';
import toasts from '@/utils/toasts';
import ApiService from '@/core/services/ApiService';

const ReferralRouteGuard = {
  verifyBusiness: () => async (to, from, next) => {
    const username = await to.params.pathMatch[0];

    return next();
  },
};

export default ReferralRouteGuard;
