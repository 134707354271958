enum Actions {
  VERIFY_ACCOUNT_TOKEN = 'accountVerificationToken',
  VERIFY_ACCOUNT_CODE = 'verifyAccountCode',
  SEND_VERIFICATION_EMAIL = 'resendVerificationEmail',
  REQUEST_NEW_TOKEN = 'requestNewToken',
}

enum Mutations {
  SET_VALID_VERIFY_TOKEN = 'setAccountVerifiedToken',
  SET_VERIFICATION_AUTH_TOKEN = 'setAccountVerificationToken',
  SET_UPLOAD_PROFILE = 'setAccountProfile',
  SET_ACTION_ERROR = 'getVerifyAccountActionError',
}

enum Getters {
  GET_VERIFY_ACCOUNT_TOKEN = 'getAccountVerifiedToken',
  GET_ACTION_ERROR = 'sgetVerifyAccountActionError',
}

export { Actions, Mutations, Getters };
