import cookie from '@dbpkgs/cookie';

export default class CookieService {
  public static option = {};

  public init(): void {
    const cookieExpiration = process.env.COOKIE_EXPIRE
      ? process.env.COOKIE_EXPIRE
      : 7;
    CookieService.option = {
      expires: new Date().setDate(new Date().getDate() + cookieExpiration),
      path: process.env.VUE_APP_BASE_URL,
      secure: true,
    };
  }

  public static setToRoot(key: string, value = '' as unknown as string) {
    const expires = new Date().setDate(
      new Date().getDate() + process.env.COOKIE_EXPIRE
        ? process.env.COOKIE_EXPIRE
        : 7
    );

    document.cookie = `${key}=${value};expires=${expires};path=/`;
  }

  public static set(key: string, value = '' as unknown as any, option = {}) {
    const cookieOption = option ? option : CookieService.option;
    cookie.set(key, value, cookieOption);
  }

  public static get(key: string): string | null {
    return cookie.get(key);
  }

  public static remove(key: string): void {
    return cookie.remove(key);
  }
}
