import { BusinessProfileType as Type } from './sign-up/types';
import * as Yup from 'yup';

export default class BusinessProfile {
  public value: Type = {
    business_name: '',
    business_email_address: '',
    business_mobile_number: '',
    same_as_business_address: false,

    business_logo: '',

    postal_address_line: '',
    postal_city: '',
    postal_state: '',
    postal_post_code: '',
    postal_country: 'AU',

    address_line: '',
    state: '',
    city: '',
    country: 'AU',
    post_code: '',
    ABN: '',
    ACN: '',
  };

  public validationSchema = Yup.object({
    business_name: Yup.string().required().label('Business Name'),
    // Begin Business Address items
    address_line: Yup.string().required().label('Office Address Line'),
    state: Yup.string().required().label('Office State'),
    city: Yup.string().required().label('Office City'),
    country: Yup.string().required().label('Office Country'),
    post_code: Yup.string().required().min(4).label('Office Post Code'),
    // Begin Postal Address items
    same_as_business_address: Yup.bool(),
    postal_address_line: Yup.string().required().label('Postal Address Line'),
    postal_state: Yup.string().required().label('Postal State'),
    postal_city: Yup.string().required().label('Postal City'),
    postal_country: Yup.string().required().label('Postal Country'),
    postal_post_code: Yup.string().required().min(4).label('Postal Post Code'),

    business_mobile_number: Yup.string()
      .matches(/(?=^.{12,12}$)/, 'Mobile Number must be at least 10 digit')
      .required()
      .label('Office Number'),
    business_email_address: Yup.string()
      .email()
      .required()
      .label('Business Email Address'),
    ABN: Yup.string()
      .required()
      .min(14, 'Must 11 digits number')
      .max(14, 'Max of 11 digits number')
      .matches(/^[0-9\s]/g, 'You must specify a number')
      .label('ABN Number'),
    ACN: Yup.string()
      .optional()
      // .min(11, 'ACN must be at least 9 digit number')
      .max(11, 'Max of 9 digits number')
      .nullable(),
  });
}
