enum Actions {}
// action types

enum Mutations {
  SET_IMAGE_PROFILE = 'setImageProfile',
  SET_IMAGE_LOGO = 'setImageLogo',
  SET_BUSINESS_PAYLOAD = 'setBusinessPayload',
  SET_PROFILE_PAYLOAD = 'setProfilePayload',
}

export { Actions, Mutations };
