import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import store from '@/store';
import { Actions, Getters, Mutations } from '@/store/enums/AuthEnums';

const ProtectedRouteGuard = {
  isLoggedIn: () => async (to, from, next) => {
    const sessionToken = JwtService.getToken();

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(Actions.VERIFY_AUTH)
      .then(async () => {
        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (!authStatus) {
          return next({ name: 'sign-in' });
        }

        // fetch auth user after login
        await store.dispatch(Actions.FETCH_AUTH_USER);

        return next();
      })
      .catch(() => {
        store.commit(
          Mutations.SET_AUTH_ROUTE_ERROR,
          'Please login to continue.'
        );

        return next({ name: 'sign-in' });
      });
  },
  main: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];

    console.log('main', {
      type: typeof user,
      user: user,
    });

    if (to.name === 'request-account-verification-mail') {
      return next();
    }

    // user is not verified
    if (user.verified_at === null) {
      return next({ name: 'request-account-verification-mail' });
    }

    return next();
  },
  isVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const queryParams = await to.query;

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (user.verified_at === null) {
      return next({
        name: 'request-account-verification-mail',
        query: queryParams,
      });
    }

    if (
      to.name === 'complete-account-profile' &&
      user.profile_completed_at !== null
    ) {
      return next({ name: 'dashboard' });
    }

    return next();
  },
  verified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const queryParams = await to.query;
    const isProfileNotCompleted = !user?.first_name || !user?.last_name || !user?.date_of_birth;

    console.log('verified don', {
      type: typeof user,
      user: user,
    });

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (user.verified_at === null && isProfileNotCompleted) {
      return next({
        name: 'request-account-verification-mail',
        query: queryParams,
      });
    }

    if(typeof user.id !== 'undefined' && isProfileNotCompleted){
      return next({ name: 'complete-account-profile' });
    }

    return next();
  },

  isNotVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const queryParams = await to.query;

    console.log('isVerified', {
      type: typeof user,
      user: user,
    });

    //check if user store is exsiting
    if (typeof user !== 'undefined') {
      if (to.name === 'request-account-verification-mail') {
        return next();
      }

      if (to.name === 'dashboard') {
        return next();
      }
    }

    return next({ query: queryParams });
  },
};

export default ProtectedRouteGuard;
