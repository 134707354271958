import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  ForgotPasswordRouteGuard,
  LoginRouteGuard,
  ProtectedRouteGuard,
  AccountVerificationRouteGuard,
  TenantReviewGuard,
} from './guards';
import ReferralRouteGuard from './guards/ReferralRouteGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'protected-pages',
    redirect: '/sign-in',
    component: () => import('@/views/v2/views/Layout.vue'),
    beforeEnter: ProtectedRouteGuard.isLoggedIn(),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/v2/views/Dashboard.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          ProtectedRouteGuard.verified(),
        ],
      },
      {
        path: '/tenant',
        name: 'tenant',
        component: () => import('@/views/v2/views/TenantReport.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          ProtectedRouteGuard.verified(),
        ],
        children: [
          {
            path: 'overview',
            name: 'tenant-profile-view',
            component: () => import('@/views/v2/views/Overview.vue'),
            children: [
              {
                path: ':ref',
                name: 'tenant-profile-view-ref',
                component: () =>
                  import(
                    '@/views/v2/views/pages/ViewTenantReview/ViewHistory.vue'
                  ),
              },
            ],
          },
          {
            path: 'submit',
            name: 'submit-tenant-review',
            component: () =>
              import('@/views/pages/tenant-reviews/SubmitTenantReview.vue'),
          },
          {
            path: 'request',
            name: 'request-tenant-report',
            component: () => import('@/views/v2/views/RequestReference.vue'),
          },
        ],
      },
      {
        path: 'new-password',
        name: 'create-new-password',
        component: () =>
          import('@/views/pages/account/create-new-password/newPassword.vue'),
      },
      // @todo group account routes
      {
        path: '/account/verify',
        name: 'request-account-verification-mail',
        component: () => import('@/views/v2/views/ResendVerification.vue'),
        beforeEnter: [ProtectedRouteGuard.isNotVerified()],
      },
      {
        path: '/account/profile/update',
        name: 'complete-account-profile',
        component: () => import('@/views/v2/views/CompleteProfile.vue'),
        beforeEnter: [ProtectedRouteGuard.isVerified()],
      },
      {
        path: '/profile',
        component: () => import('@/views/v2/views/Profile.vue'),
        name: 'my-profile-over-view',
        redirect: '/profile/overview',
        beforeEnter: [ProtectedRouteGuard.main()],
        children: [
          {
            path: '/profile/overview',
            component: () =>
              import('@/views/v2/views/pages/Profile/Overview.vue'),
          },
          {
            path: '/profile/change-password',
            component: () =>
              import('@/views/v2/views/pages/Profile/PasswordReset.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('@/views/v2/views/Login.vue'),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: [LoginRouteGuard.isLoggedIn()],
  },
  {
    path: '/sign-in-options',
    name: 'sign-in-options',
    component: () => import('@/views/v2/views/SignInOptions.vue'),
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import('@/views/v2/views/TermsConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/v2/views/PrivacyPolicy.vue'),
  },
  {
    path: '/account',
    name: 'acccount-layout',
    component: () => import('@/views/v2/views/NoAuthLayout.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'verify',
        name: 'account-verification',
        component: () => import('@/views/v2/views/Verify.vue'),
        children: [
          {
            path: ':token',
            name: 'validate-verify-account-token',
            component: () => import('@/views/pages/tenant/VerifyTenant.vue'),
            beforeEnter: AccountVerificationRouteGuard.verifyToken(),
          },
        ],
      },
    ],
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/v2/views/SignUp.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/v2/views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password-page',
    component: () => import('@/views/v2/views/ResetPassword.vue'),
    children: [
      {
        path: ':token',
        name: 'verify-reset-password-token',
        component: () => import('@/views/v2/views/ResetPassword.vue'),
        beforeEnter: ForgotPasswordRouteGuard,
      },
    ],
  },
  {
    path: '/review',
    name: 'tenant-review-page',
    // component: () => import('@/views/TenantReview.vue'),
    component: () => import('@/views/v2/views/Layout.vue'),
    children: [
      {
        path: ':reference_no',
        name: 'check-tenant-review-page',
        component: () =>
          import('@/views/pages/tenant-reviews/CheckTenantReview.vue'),
        beforeEnter: TenantReviewGuard.checkReferenceNo(),
      },
      {
        path: 'verify',
        name: 'verify-account-page',
        component: () => import('@/views/pages/tenant/VerifyTenant.vue'),
        beforeEnter: AccountVerificationRouteGuard.sendRequest(),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/v2/views/NoAuthLayout.vue'),
    children: [
      {
        path: 'contact-us',
        name: 'contact-us',
        component: () => import('@/views/v2/views/ContactUs.vue'),
      },
      {
        path: 'contact-us/:ticketNo',
        name: 'view-support-ticket',
        component: () => import('@/views/v2/views/ViewSupportTicket.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/a/:pathMatch(.*)*',
    name: 'landing-page',
    component: () => import('@/views/v2/views/SignUp.vue'),
    beforeEnter: ReferralRouteGuard.verifyBusiness(),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  //store.commit(StoreMutations.RESET_LAYOUT_CONFIG);

  // if (!store.getters.isAuthenticated && to.meta.requiresAuth) {
  //   next({ name: 'sign-in' });
  // }

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
