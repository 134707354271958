import ApiService from '@/core/services/ApiService';
import { StoreError, ReferenceRequestModuleStore } from '@/models/StoreModel';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  Actions,
  Getters,
  Mutations,
} from '@/store/enums/ReferenceRequestEnums';
import { ReferenceRequest } from '@/models/ReferenceRequestModel';

@Module
export default class ReferenceRequestModule
  extends VuexModule
  implements ReferenceRequestModuleStore
{
  actionError: StoreError | null = null;

  allReferenceRequests = [];
  referenceRequest = {} as unknown as ReferenceRequest;
  referenceRequests = [];

  requestError: StoreError | null = null;
  requestLoading = false;

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](actionError) {
    this.actionError = actionError;
  }

  @Action
  [Actions.SEND_REQUEST_TENANT_REFERENCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('reviews/requests', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
