import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/PayloadEnum';
import { Mutations as StoreMutations } from '@/store/enums/StoreEnums';
import { Module, Action, VuexModule, Mutation } from 'vuex-module-decorators';
import { BusinessProfileType, AgentProfileType } from '@/models/sign-up/types';
import BusinessProfileModel from '@/models/BusinessModel';
import AgentProfileModel from '@/models/agentProfile';
import Cookies from 'js-cookie';

interface AccountVerify {
  ProfileUpload: File | null;
  BusinessLogo: File | null;
  BusinessProfile: BusinessProfileType;
  AgentProfile: AgentProfileType;
}

@Module
export default class PayloadModule extends VuexModule implements AccountVerify {
  ProfileUpload = null;
  BusinessLogo = null;
  BusinessProfile;
  AgentProfile;

  constructor() {
    super(VuexModule);
    this.BusinessProfile = Cookies.get('business_profile_model')
      ? JSON.parse(Cookies.get('business_profile_model'))
      : new BusinessProfileModel().value;

    this.AgentProfile = Cookies.get('agent_profile_model')
      ? JSON.parse(Cookies.get('agent_profile_model'))
      : new AgentProfileModel().value;
  }

  @Mutation
  [Mutations.SET_IMAGE_PROFILE](payload) {
    this.ProfileUpload = payload;
  }

  @Mutation
  [Mutations.SET_IMAGE_LOGO](payload) {
    this.BusinessLogo = payload;
  }

  @Mutation
  [Mutations.SET_BUSINESS_PAYLOAD](payload) {
    this.BusinessProfile = payload;
  }

  @Mutation
  [Mutations.SET_PROFILE_PAYLOAD](payload) {
    this.AgentProfile = payload;
  }

  get profileUpload(): File | null {
    return this.ProfileUpload;
  }

  get businessLogoUpload(): File | null {
    return this.BusinessLogo;
  }

  get businessProfile() {
    return this.BusinessProfile;
  }

  get agentProfile() {
    return this.AgentProfile;
  }
}
