enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  UPDATE_USER = 'updateUser',
  UPDATE_BUSINESS = 'updateBusiness',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  FETCH_USER_PROFILE_AVATAR = 'fetchUserAvatar',
}

enum Mutations {
  // mutation types
  SET_USER_AVATAR = 'setUserAvatarPicture',
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_SYSTEM_ERROR = 'setSystemError',
}

enum Getters {
  // mutation types
  GET_USER_AVATAR = 'setUserAvatarPicture',
  GET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  GET_ERROR = 'setError',
  GET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  GET_LAYOUT_CONFIG = 'setLayoutConfig',
  GET_SYSTEM_ERROR = 'getSystemError',
}

export { Actions, Mutations, Getters };
