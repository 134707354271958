import store from '@/store';
import { Actions } from '@/store/enums/TenantReviewEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';
import _ from 'lodash';

const TenantReviewGuard = {
  checkReferenceNo: () => async (to, from, next) => {
    const referenceNo = await to.params.reference_no;
    const token = await store.getters[AuthGetters.GET_AUTH_TOKEN];

    return await store
      .dispatch(Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO, referenceNo)
      .then(async () => {
        const user = await store.getters[AuthGetters.GET_AUTH_USER];

        // if(!token){
        //   return next({ name: 'sign-in-options', query: { ref: referenceNo } });
        // }

        if (typeof user !== 'undefined' && user.verified_at === null) {
          console.log('to verify-account-page');

          return next({
            name: 'verify-account-page',
            query: { ref: referenceNo },
          });
        }

        // if (typeof user !== 'undefined' && user.verified_at !== null && user.profile_completed_at === null) {
        //   return next({ name: 'complete-account-profile' });
        // }

        console.log('to tenant-profile-view');

        return next({
          name: 'tenant-profile-view',
          query: { ref: referenceNo },
        });
      })
      .catch((response) => {
        toasts.error([], response);
        return next();
      });
  },
};

export default TenantReviewGuard;
