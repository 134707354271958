import moment from 'moment';

const formats = {
  default: 'YYYY-MM-DD',
  aus: 'DD/MM/YYYY',
  short: 'DD/MM/YY'
};

export const time = {
  convert24to12: (time: string) => {
    if (!time) return '';

    const [hour, minute] = time.split(':');

    const hourNumber = parseInt(hour);

    const ampm = hourNumber <= 11 ? 'AM' : hourNumber === 24 ? 'AM' : 'PM';
    const parsedHour = hourNumber > 12 ? hourNumber % 12 : hourNumber;

    return `${parsedHour}:${minute} ${ampm}`;
  },

  dateFormat: (time: string, format = 'aus') => {
    if (!time) return '';

    return moment(time).format(formats[format]);
  },
};

export default time;
