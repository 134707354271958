enum Actions {
  SEND_REQUEST_TENANT_REFERENCE = 'sendRequestTenantReference',
}

enum Mutations {
  SET_ACTION_ERROR = 'setReferenceActionError',
}

enum Getters {
  GET_ACTION_ERROR = 'getReferenceActionError',
}

export { Actions, Mutations, Getters };
