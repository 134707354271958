enum Actions {
  FETCH_ALL_TENANT_REVIEWS = 'fetchAllTenantReviews',
  FETCH_TENANT_REVIEW = 'fetchTenantReview',
  FETCH_TANANT_REVIEW_BY_REFERENCE_NO = 'fetchTenantReviewByRefNo',
  SUBMIT_TENANT_REVIEW = 'submitTenantReview',
  UPLOAD_TENANT_REVIEW_LEDGER = 'uploadTenantReviewLedger',
  FETCH_TENANT_REVIEW_LEDGER = 'fetchTenantReviewLedger',
  SEND_TENANT_REVIEW_TO_EMAILS = 'sendTenantReviewToEmails',
  SEND_EMPTY_REPORT_TO_EMAILS = 'sendEmptyReportToEmails',
  FETCH_BUSINESS_LOGO = 'fetchBusinessLogo',
  SUBMIT_COMMENT = 'submitComment',
  FETCH_COMMENTS = 'fetchComments',
  FETCH_STREETVIEW_IMAGE = 'fetchStreetViewImage',
  FETCH_GOOGLEMAP_IMAGE = 'fetchGoogleMapImage',
}

enum Mutations {
  SET_ALL_TENANT_REVIEWS = 'setAllTenantReviews',
  SET_TENANT_REVIEW = 'setTenantReview',
  SET_TENANT_REVIEWS = 'setTenantReviews',
  SET_TENANT_REVIEW_LIST_ERROR = 'setTenantReviewListError',
  SET_TENANT_REVIEW_LIST_LOADING = 'setTenantReviewListLoading',
  SET_TENANT_REVIEW_LOADING = 'setTenantReviewLoading',
  SET_TENANT_REVIEW_ACTION_ERROR = 'setTenantReviewActionError',
  SET_TENANT_REVIEW_LEDGER_FILE = 'setTenantReviewLedgerFile',
  SET_BUSINESS_LOGO = 'setBusinessLogo',
  SET_COMMENTS = 'setComments',
  SET_STREETVIEW_IMAGE = 'setStreetViewImage',
}

enum Getters {
  GET_ALL_TENANT_REVIEWS = 'getAllTenantReviews',
  GET_TENANT_REVIEWS = 'getTenantReviews',
  GET_TENANT_REVIEW = 'getTenantReview',
  GET_TENANT_REVIEW_ACTION_ERROR = 'getTenantReviewActionError',
  GET_TENANT_REVIEW_LIST_ERROR = 'getTenantReviewListError',
  GET_TENANT_REVIEW_LIST_LOADING = 'getTenantReviewListLoading',
  GET_TENANT_REVIEW_LOADING = 'getTenantReviewLoading',
  GET_TENANT_REVIEW_LEDGER_FILE = 'getTenantReviewLedgerFile',
  GET_BUSINESS_LOGO = 'getBusinessLogo',
  GET_COMMENTS = 'getComments',
  GET_STREETVIEW_IMAGE = 'getStreetViewImage',
}

export { Actions, Mutations, Getters };
