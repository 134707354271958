import { createApp } from 'vue';
import App from './App.vue';
import 'dotenv/config';

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';
import VueTheMask from 'vue-the-mask';
import VueGoogleMaps from '@fawmi/vue-google-maps'
//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import { initSystemFilters } from '@/core/plugins/SystemFilters';
import 'lodash';

import '@/core/plugins/keenthemes';
import '@/core/plugins/prismjs';
import 'bootstrap';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
initSystemFilters(app);

app.use(i18n);
app.use(VueTheMask);
app.use(VueGoogleMaps, {
    load: {
        version: 'weekly',
        key: process.env.VUE_APP_GOOGLEMAPS_KEY,
        libraries: "places"
    },
})
app.mount('#app');
