enum Actions {
  // action types
  SEND_SUPPORT_TICKET = 'sendSupportTicket',
  FIND_SUPPORT_TICKET = 'findSupportTicket',
}

enum Mutations {
  SET_SUPPORT_TICKET = 'setSupportTicket',
  SET_CONTACT_SUPPRT_ERROR = 'setContactSupportError',
  SET_CONTACT_SUPPRT_ACTION_ERROR = 'setContactSupportActionError',
}
// mutation types

enum Getters {
  GET_SUPPORT_TICKET = 'getSupportTicket',
  GET_CONTACT_SUPPRT_ERROR = 'getContactSupportError',
  GET_CONTACT_SUPPRT_ACTION_ERROR = 'getContactSupportActionError',
}

export { Actions, Mutations, Getters };
