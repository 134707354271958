enum Actions {
  GET_ALL_TENANTS = 'retrieveAllTenant',
  SEARCH_TENANT = 'searchTenant',
  SEND_TENANT_REFERENCE = 'sendTenantReference',
  SUBMIT_COMPLETE_PROFILE = 'submitTenantCompleteProfile',
}

enum Mutations {
  SET_SEARCH_TENANT_LIST = 'setSearchTenantList',
  SET_ALL_TENANTS = 'setAllTenants',
  SET_TENANT = 'setTenant',
  SET_TENANTS = 'setTenants',
  SET_TENANT_LIST_ERROR = 'setTenantListError',
  SET_SEARCH_TENANT_ACTION_ERROR = 'setSearchTenantActionError',
  SET_TENANT_LIST_LOADING = 'setTenantListLoading',
  SET_TENANT_LOADING = 'setTenantLoading',
  SET_TENANT_ACTION_ERROR = 'setTenantActionError',
}

enum Getters {
  GET_SEARCH_TENANT_LIST = 'getSearchTenantList',
  GET_ALL_TENANTS = 'getAllTenants',
  GET_TENANTS = 'getTenants',
  GET_TENANT = 'getTenant',
  GET_SEARCH_TENANT_ACTION_ERROR = 'getSearchTenantActionError',
  GET_TENANT_ACTION_ERROR = 'getTenantActionError',
  GET_TENANT_LIST_ERROR = 'getTenantListError',
  GET_TENANT_LIST_LOADING = 'getTenantListLoading',
  GET_TENANT_LOADING = 'getTenantLoading',
}

export { Actions, Mutations, Getters };
