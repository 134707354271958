import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import { Actions, Getters, Mutations } from '@/store/enums/UserEnums';

import { imageBuilder } from '@/core/utils/PayloadSanitized';
import { UserModuleStore, StoreError, SystemError } from '@/models/StoreModel';
import { User } from '@/models/UserModel';
import { Actions as AuthActions } from '@/store/enums/AuthEnums';
import { AxiosRequestConfig } from 'axios';

export interface TokenizedUser extends User {
  id: string;
  token: string;
  two_factor_enabled: string | null | boolean;
  user_roles: Array<string> | string;
}

@Module
export default class UserModule extends VuexModule implements UserModuleStore {
  actionError: StoreError | null = null;
  systemError: SystemError | null = null;

  allUsers = [];
  user = {} as unknown as User;
  users = [];

  listError: StoreError | null = null;
  listLoading = false;

  avatar = '';

  /**
   * Get users
   * @returns array
   */
  get [Getters.GET_ALL_USERS](): User[] {
    return this.users;
  }

  @Mutation
  [Mutations.SET_ALL_USERS](users) {
    this.users = users;
  }

  /**
   * Get users
   * @returns array
   */
  get [Getters.GET_USERS](): User[] {
    return this.users;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  /**
   * Get user
   * @returns array
   */
  get [Getters.GET_USER](): User {
    return this.user as unknown as User;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_ACTION_ERROR](error) {
    this.actionError = error;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get [Getters.GET_USER_LIST_ERROR](): StoreError {
    return this.listError as StoreError;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get [Getters.GET_USER_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_USER_LIST_ERROR](error) {
    this.listError = error;
  }

  @Action
  [Actions.CREATE_NEW_USER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('account/sign-up', credentials)
        .then(() => resolve())
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_USER_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
