import { createStore } from 'vuex';
import $ from 'vue-cookies';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import UserModule from '@/store/modules/UserModule';
import TenantModule from '@/store/modules/TenantModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import Resources from '@/store/modules/ResourcesModule';
import TenantReviewModule from '@/store/modules/TenantReviewModule';
import ReviewQuestionModule from '@/store/modules/ReviewQuestionModule';
import ManageUserModule from '@/store/modules/ManageUserModule';
import SettingsModule from '@/store/modules/SettingsModule';
import PayloadModule from '@/store/modules/PayloadModule';
import ReferenceRequestModule from '@/store/modules/ReferenceRequestModule';
import VerifyAccountModule from '@/store/modules/VerifyAccountModule';
import ForgotPasswordModule from '@/store/modules/ForgotPasswordModule';

import ContactSupportModule from '@/store/modules/ContactSupportModule';

config.rawError = true;

const store = createStore({
  modules: {
    Resources,
    BodyModule,
    ConfigModule,
    BreadcrumbsModule,
    UserModule,
    AuthModule,
    TenantModule,
    TenantReviewModule,
    ReviewQuestionModule,
    PayloadModule,
    ReferenceRequestModule,
    VerifyAccountModule,
    ForgotPasswordModule,
    ContactSupportModule,
  },
});

export default store;
