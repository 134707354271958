import { AgentProfileType as Type } from './sign-up/types';
import * as Yup from 'yup';

export default class AgentProfile {
  public value: Type = {
    first_name: '',
    last_name: '',
    address_line: '',
    city: '',
    state: '',
    post_code: '',
    country: 'AU',
    profile_picture: '',
  };

  public validationSchema = Yup.object({
    first_name: Yup.string().required().label('First Name'),
    last_name: Yup.string().required().label('Surname'),
    address_line: Yup.string().required().label('Address Line'),
    state: Yup.string().required().label('State'),
    city: Yup.string().required().label('City'),
    country: Yup.string().required().label('Country'),
    post_code: Yup.string().required().min(4).label('Post Code'),
  });
}
