import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/ContactSupportEnums';
import { ContactSupportModuleStore, StoreError } from '@/models/StoreModel';
import { ContactSupport } from '@/models/ContactSupportModel';

@Module
export default class ContactSupportModule
  extends VuexModule
  implements ContactSupportModuleStore
{
  allContactSupport = [];
  contactSupports = [];
  contactSupport = {} as unknown as ContactSupport;

  actionError: StoreError | null = null;

  get [Getters.GET_CONTACT_SUPPRT_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_SUPPORT_TICKET](): ContactSupport {
    return this.contactSupport as ContactSupport;
  }

  @Mutation
  [Mutations.SET_CONTACT_SUPPRT_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_SUPPORT_TICKET](contactSupport) {
    this.contactSupport = contactSupport;
  }

  /**
   * Send Support Ticket
   * @param payload
   * @returns
   */
  @Action
  [Actions.SEND_SUPPORT_TICKET](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('support/ticket', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUPPORT_TICKET, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_CONTACT_SUPPRT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  /**
   * Send Support Ticket
   * @param payload
   * @returns
   */
  @Action
  [Actions.FIND_SUPPORT_TICKET](ticketNo) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('support/ticket', ticketNo)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUPPORT_TICKET, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_CONTACT_SUPPRT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
