import store from '@/store';
import { Getters, Actions, Mutations } from '@/store/enums/AuthEnums';
import {
  Actions as VerifyActions,
  Getters as VerifyGetters,
} from '@/store/enums/VerifyAccountEnums';
import CookieService from '@/core/services/CookieService';
import toasts from '@/utils/toasts';
import ApiService from '@/core/services/ApiService';

// export default AccountVerification;
const AccountVerificationRouteGuard = {
  verifyToken: () => async (to, from, next) => {
    const queryParams = await to.query;

    const payload = {
      token: await to.params.token,
    };

    // remove current user from store
    await store.dispatch(Actions.CLEAR_AUTH_USER);

    // verify account
    return await store
      .dispatch(VerifyActions.VERIFY_ACCOUNT_TOKEN, payload)
      .then(async () => {
        const authToken = await store.getters[Getters.GET_AUTH_TOKEN];

        CookieService.set('sessionToken', authToken);

        // fetch current user before going to complete profile

        // goto view tenant review
        if (
          typeof queryParams.target !== 'undefined' &&
          queryParams.target === 'view_review'
        ) {
          return next({
            name: 'tenant-profile-view-ref',
            params: { ref: queryParams.ref },
            query: { t: 'new-setup' },
          });
        }

        // show create password guide
        return next({ name: 'dashboard', query: { t: 'complete-profile' } });
      })
      .catch(() => {
        return toasts.error(
          [],
          'Tenant account link might have expired. Please register to verify account.',
          () => {
            return next({
              name: 'account-verification',
              query: queryParams,
            });
          }
        );
      });
  },

  request: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const sessionToken = CookieService.get('sessionToken');
    const queryParams = await to.query;

    console.log('queryParams', queryParams);

    if (typeof user !== 'undefined' && user.verified_at !== null) {
      return next({ name: 'complete-account-profile' });
    }

    const payload = {
      tenant_review_no: queryParams.ref ? queryParams.ref : '',
      target: queryParams.target ? queryParams.target : '',
    };

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(VerifyActions.REQUEST_NEW_TOKEN, payload)
      .then(async () => {
        return next();
      })
      .catch(() => {
        const { errors, messages } =
          store.getters[VerifyGetters.GET_ACTION_ERROR];
        toasts.error(errors, messages);
        return next();
      });
  },

  sendRequest: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const sessionToken = CookieService.get('sessionToken');
    const queryParams = await to.query;

    console.log('queryParams', queryParams);

    if (typeof user !== 'undefined' && user.verified_at !== null) {
      return next({ name: 'complete-account-profile' });
    }

    const payload = {
      tenant_review_no: queryParams.ref ? queryParams.ref : '',
      target: queryParams.target ? queryParams.target : '',
    };

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(VerifyActions.SEND_VERIFICATION_EMAIL, payload)
      .then(async () => {
        return next();
      })
      .catch(() => {
        const { errors, messages } =
          store.getters[VerifyGetters.GET_ACTION_ERROR];
        toasts.error(errors, messages);
        return next();
      });
  },
};

export default AccountVerificationRouteGuard;
