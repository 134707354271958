import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { TenantReview } from '@/models/TenantReviewModel';
import { ReviewAnswer } from '@/models/ReviewAnswerModel';
import { TenantReviewModuleStore, StoreError } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/TenantReviewEnums';
import {
  Actions as AuthActions,
  Getters as AuthGetters,
  Mutations as AuthMutations,
} from '../enums/AuthEnums';
import { get } from 'lodash';
import { AxiosRequestConfig } from 'axios';

@Module
export default class TenantReviewModule
  extends VuexModule
  implements TenantReviewModuleStore
{
  actionError: StoreError | null = null;

  allTenantReview = [];
  tenantReview = {} as unknown as TenantReview;
  tenantReviews = [];

  tenantReviewListError = null;
  tenantReviewLoading = false;
  tenantReviewListLoading = false;
  tenantLedgerFile = '';
  logo = '';
  /**
   * Get all tenant reviews
   * @returns TenantReview
   */
  get [Getters.GET_ALL_TENANT_REVIEWS](): TenantReview[] {
    return this.allTenantReview;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEWS](): TenantReview[] {
    return this.tenantReviews;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEW](): TenantReview {
    return this.tenantReview as unknown as TenantReview;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_TENANT_REVIEW_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_TENANT_REVIEW_LIST_ERROR](): StoreError {
    return this.tenantReviewListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LIST_LOADING](): StoreError {
    return this.tenantReviewListLoading as unknown as StoreError;
  }

  get [Getters.GET_TENANT_REVIEW_LEDGER_FILE](): any {
    return this.tenantLedgerFile;
  }

  get [Getters.GET_BUSINESS_LOGO](): string {
    return this.logo;
  }

  @Mutation
  [Mutations.SET_BUSINESS_LOGO](logo) {
    this.logo = logo;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LEDGER_FILE](tenantLedgerFile) {
    this.tenantLedgerFile = tenantLedgerFile;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_ACTION_ERROR](actionError) {
    this.actionError = actionError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LOADING](): StoreError {
    return this.tenantReviewLoading as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_ALL_TENANT_REVIEWS](tenantReviews) {
    this.allTenantReview = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW](tenantReview) {
    this.tenantReview = tenantReview;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEWS](tenantReviews) {
    this.tenantReviews = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LIST_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_ALL_TENANT_REVIEWS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query('reviews', {
        params: { returnAll: true },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_TENANT_REVIEWS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('reviews', id)
        .then((data) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO](reference_no: string) {
    const payload = {
      reference_no: reference_no,
    } as AxiosRequestConfig;
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`reviews/check`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, data.data.review);
          this.context.commit(AuthMutations.SET_AUTH, data.data);
          this.context.commit(AuthMutations.SET_AUTH_USER, data.data.user);
          resolve();
        })
        .catch((response) => {
          console.log(response);
          // this.context.commit(Mutations.SET_TENANT_REVIEW_ACTION_ERROR, data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_LEDGER](refId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/reviews/${refId.refId}/ledger`, '', {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LEDGER_FILE,
            response.data
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_TENANT_REVIEW_TO_EMAILS](params) {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      formData.append('emails', JSON.stringify(params.emails));
      formData.append('refNo', params.refNo);
      formData.append('report', params.report);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      ApiService.post(`/reviews/${params.refNo}/send`, formData, config)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_EMPTY_REPORT_TO_EMAILS](params) {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      formData.append('emails', JSON.stringify(params.emails));
      formData.append('report', params.report);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      ApiService.post(`/reviews/send`, formData, config)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_BUSINESS_LOGO](refNo) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(`/reviews/${refNo}/business/logo`, axiosRequestConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // COMMENTS
  comments = [] as any;

  get [Getters.GET_COMMENTS](): any {
    return this.comments;
  }

  @Mutation
  [Mutations.SET_COMMENTS](data: any) {
    this.comments = data;
  }

  @Action
  [Actions.SUBMIT_COMMENT](payload: any) {
    const data = {
      comment: payload.comment,
      parent_id: payload.parent_id,
    } as any;
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`reviews/${payload.ref_id}/comments`, data)
        .then(({ data }) => {
          resolve();
        })
        .catch((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_COMMENTS](refId: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`reviews/${refId}/comments`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMMENTS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // STREET VIEW IMAGE
  streetViewImage = null as any;

  get [Getters.GET_STREETVIEW_IMAGE](): any {
    return this.streetViewImage;
  }

  @Mutation
  [Mutations.SET_STREETVIEW_IMAGE](data: any) {
    this.streetViewImage = data;
  }

  @Action
  [Actions.FETCH_STREETVIEW_IMAGE](params: any) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(
        `reviews/${params.reviewRefNo}/street`,
        axiosRequestConfig
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  // GOOGLE MAP IMAGE
  @Action
  [Actions.FETCH_GOOGLEMAP_IMAGE](params: any) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(`reviews/${params.reviewRefNo}/map`, axiosRequestConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }
}
